import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { Form, Field } from 'react-final-form';
import { MailTwoTone, EnvironmentTwoTone } from '@ant-design/icons'
import { updateScrollbarScale } from '../components/store'

import SEO from '../components/seo';

const formVariants = {
	hidden: { x: -600, scaleX: 0, opacity: 0 },
	visible: { x: 0, scaleX: 1, opacity: 1 },
	submitting: { x: 600, scaleX: 0, opacity: 0 }
};

const pathVariants = {
	hidden: {
		pathLength: 0
	},
	visible: {
		pathLength: 1
	}
};
const svgVariants = {
	hidden: {
		scale: 0,
		opacity: 0
	},
	visible: {
		scale: 1,
		opacity: 1
	}
};

const textVariants = {
	hidden: {
		opacity: 0,
		y: 50
	},
	visible: {
		opacity: 1,
		y: 0
	}
};

const letterVariants = {
	visible: {
		opacity: 1,
		y: 0
	},
	hidden: {
		opacity: 0,
		y: -80
	}
}

const iconVariants = {
	hidden: {
		opacity: 0,
		scale: 0
	},
	visible: {
		opacity: 1,
		scale: 1
	}
}

const TitleBig = ({ title, subtitle }) => (
		<h2>
			<div>
				{title.split('').map((c, i) => (
					<motion.span
						key={i}
						variants={letterVariants}
						initial="hidden"
						animate="visible"
						transition={{
							delay: 0.05 * i + 0.35,
							type: 'spring'
						}}
						style={{ display: 'inline-block' }}
					>
						{c}
					</motion.span>
				))}
			</div>
			<span>
				<div>
					{subtitle.split('').map((c, i) => (
						<motion.span
							key={i}
							variants={letterVariants}
							initial="hidden"
							animate="visible"
							transition={{
								delay: 0.05 * i + 0.35,
								type: 'spring'
							}}
							style={{ display: 'inline-block' }}
						>
							{c}
						</motion.span>
					))}
				</div>
			</span>
		</h2>
	);

const ContactForm = ({ visible }) => {
	const [ formStatus, setFormStatus ] = useState('active');

	const handleSubmit = data => {
		setFormStatus('submitting');

		fetch('/api/contact', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(res => {
				if (res.success) {
					setFormStatus('success');

					setTimeout(() => {
						setFormStatus('success-end');

						setTimeout(() => {
							setFormStatus('active');
						}, 750);
					}, 5000);
				}
				else {
					setFormStatus('error');

					setTimeout(() => {
						setFormStatus('error-end');

						setTimeout(() => {
							setFormStatus('active');
						}, 750);
					}, 5000);
				}
			});
	};

	return (
		<Form
			onSubmit={handleSubmit}
			render={({ handleSubmit, form, submitting, pristine, values }) => (
				<form
					className={formStatus === 'submitting' ? 'submitting' : ''}
					onSubmit={handleSubmit}
					style={{ pointerEvents: (formStatus === 'active' && visible) ? 'all' : 'none' }}
				>
					<motion.div
						initial="hidden"
						animate={formStatus === 'submitting' ? 'submitting' : ((formStatus === 'active' && visible) ? 'visible' : 'hidden')}
						variants={formVariants}
						transition={{
							delay: visible ? 0.35 : 0,
							duration: 1,
							type: 'spring'
						}}
					>
						<Field name="name" component="input" placeholder="Nom et prénom*" />
					</motion.div>
					<motion.div
						initial="hidden"
						animate={formStatus === 'submitting' ? 'submitting' : ((formStatus === 'active' && visible) ? 'visible' : 'hidden')}
						variants={formVariants}
						transition={{
							delay: visible ? 0.50 : 0.15,
							duration: 1,
							type: 'spring'
						}}
					>
						<Field name="email" component="input" placeholder="Email*" />
					</motion.div>
					<motion.div
						initial="hidden"
						animate={formStatus === 'submitting' ? 'submitting' : ((formStatus === 'active' && visible) ? 'visible' : 'hidden')}
						variants={formVariants}
						transition={{
							delay: visible ? 0.65 : 0.3,
							duration: 1,
							type: 'spring'
						}}
					>
						<Field name="phone" component="input" placeholder="Téléphone" />
					</motion.div>
					<motion.div
						initial="hidden"
						animate={formStatus === 'submitting' ? 'submitting' : ((formStatus === 'active' && visible) ? 'visible' : 'hidden')}
						variants={formVariants}
						transition={{
							delay: visible ? 0.8 : 0.45,
							duration: 1,
							type: 'spring'
						}}
					>
						<Field name="message" component="textarea" placeholder="Votre message*" />
					</motion.div>
					<motion.div
						initial="hidden"
						animate={formStatus === 'submitting' ? 'submitting' : ((formStatus === 'active' && visible) ? 'visible' : 'hidden')}
						variants={formVariants}
						transition={{
							delay: visible ? 0.95 : 0.6,
							duration: 1,
							type: 'spring'
						}}
					>
						<button type="submit" disabled={submitting || pristine || (!values.name || !values.email || !values.message)}>Envoyer</button>
					</motion.div>

					<div className="form-success" style={{ display: (formStatus === 'active' && visible) ? 'none' : '', pointerEvents: formStatus === 'success' ? 'all' : 'none' }}>
						<motion.div
							variants={svgVariants}
							initial="visible"
							animate={formStatus === 'success-end' ? 'hidden' : 'visible'}
							transition={{
								duration: 0.6
							}}
							style={{ display: 'inline-block', width: 144, height: 144 }}
						>
							<svg viewBox="0 0 60 60">
								<motion.path
									fill="none"
									strokeWidth="5"
									stroke="black"
									strokeDasharray="0 1"
									d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
									initial={{
										pathLength: 0
									}}
									animate={{
										pathLength: (formStatus === 'success' || formStatus === 'success-end') ? 1 : 0,
									}}
									transition={{
										duration: 0.5
									}}
									style={{
										rotate: 90,
										translateX: 5,
										translateY: 5,
										scaleX: -1
									}}
								/>
								<motion.path
									fill="none"
									strokeWidth="5"
									stroke="black"
									strokeDasharray="0 1"
									d="M14,26 L 22,33 L 35,16"
									initial={{
										pathLength: 0
									}}
									animate={{
										pathLength: (formStatus === 'success' || formStatus === 'success-end') ? 1 : 0
									}}
									transition={{
										duration: 0.5
									}}
								/>
							</svg>
						</motion.div>

						<motion.p
							initial="hidden"
							variants={textVariants}
							animate={formStatus === 'success' ? 'visible' : 'hidden'}
							transition={{
								delay: formStatus === 'success-end' ? 0 : 0.2,
								type: 'spring'
							}}
						>
							Merci d'avoir pris contact avec nous. Nous traiterons votre demande dans les plus brefs délais.
						</motion.p>
					</div>

					<div className="form-success" style={{ display: (formStatus === 'active' && visible) ? 'none' : '', pointerEvents: formStatus === 'error' ? 'all' : 'none' }}>
						<motion.p
							initial="hidden"
							variants={textVariants}
							animate={formStatus === 'error' ? 'visible' : 'hidden'}
							transition={{
								delay: formStatus === 'error-end' ? 0 : 0.2,
								type: 'spring'
							}}
						>
							Erreur lors de l'envoi de l'email, merci de bien vouloir réessayer.
						</motion.p>
					</div>
				</form>
			)}
		/>
	);
};

const Contact = ({ dispatch }) => {
	const [ contactForm, setContactForm ] = useState(true);

	useEffect(() => {
		dispatch(updateScrollbarScale(100));

		let t = setTimeout(() => {
			const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight);
			const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
			dispatch(updateScrollbarScale(viewportHeight / documentHeight * 100));
		}, 400);

		return () => clearTimeout(t);
	}, [dispatch]);

	return (
		<section className="contact">
			<SEO
				title="Contact"
				description="Prenez rendez-vous avec nous pour nous parler de votre projet."
			/>

			<motion.div
				className="bar-1"
				animate={{
					backgroundPositionY: 0
				}}
				transition={{
					delay: 0.35,
					duration: 1,
					type: 'spring'
				}}
			/>
			<motion.div
				className="bar-2"
				animate={{
					backgroundPositionY: 0
				}}
				transition={{
					delay: 0.35,
					duration: 1,
					type: 'spring'
				}}
			/>

			<div className="text-content">
				<TitleBig title="Contactez" subtitle="NOUS" />

				<ContactForm visible={contactForm} />

				<address className="address-desktop">
					<motion.p
						initial="hidden"
						animate="visible"
						variants={formVariants}
						transition={{
							delay: contactForm ? 0 : 0.35
						}}
					>
						<a href="https://www.google.com/maps/place/nammu.ch+-+Agence+Web/@46.229632,6.0597823,17z/data=!3m1!4b1!4m5!3m4!1s0x478c6311ba4d5cd1:0x38a4c4cc2d93a856!8m2!3d46.229632!4d6.061971" target="_blank" rel="nooponer nofollow">
							15 route du Mandement
						</a>
					</motion.p>
					<motion.p
						initial="hidden"
						animate="visible"
						variants={formVariants}
						transition={{
							delay: contactForm ? 0.25 : 0.6
						}}
					>
						<a href="https://www.google.com/maps/place/nammu.ch+-+Agence+Web/@46.229632,6.0597823,17z/data=!3m1!4b1!4m5!3m4!1s0x478c6311ba4d5cd1:0x38a4c4cc2d93a856!8m2!3d46.229632!4d6.061971" target="_blank" rel="nooponer nofollow">
							1217 Meyrin, Genève
						</a>
					</motion.p>
					<motion.p
						initial="hidden"
						animate="visible"
						variants={formVariants}
						style={{ marginTop: 20 }}
						transition={{
							delay: contactForm ? 0.5 : 0.85
						}}
					>
						<a href="mailto:info@nammu.ch">info@nammu.ch</a>
					</motion.p>
				</address>

				<address>
					<motion.p
						initial="hidden"
						animate={contactForm ? 'hidden' : 'visible'}
						variants={formVariants}
						transition={{
							delay: contactForm ? 0 : 0.35
						}}
					>
						<a href="https://www.google.com/maps/place/nammu.ch+-+Agence+Web/@46.229632,6.0597823,17z/data=!3m1!4b1!4m5!3m4!1s0x478c6311ba4d5cd1:0x38a4c4cc2d93a856!8m2!3d46.229632!4d6.061971" target="_blank" rel="nooponer nofollow">
							15 route du Mandement
						</a>
					</motion.p>
					<motion.p
						initial="hidden"
						animate={contactForm ? 'hidden' : 'visible'}
						variants={formVariants}
						transition={{
							delay: contactForm ? 0.25 : 0.6
						}}
					>
						<a href="https://www.google.com/maps/place/nammu.ch+-+Agence+Web/@46.229632,6.0597823,17z/data=!3m1!4b1!4m5!3m4!1s0x478c6311ba4d5cd1:0x38a4c4cc2d93a856!8m2!3d46.229632!4d6.061971" target="_blank" rel="nooponer nofollow">
							1217 Meyrin, Genève
						</a>
					</motion.p>
					<motion.p
						initial="hidden"
						animate={contactForm ? 'hidden' : 'visible'}
						variants={formVariants}
						style={{ marginTop: 20 }}
						transition={{
							delay: contactForm ? 0.5 : 0.85
						}}
					>
						<a href="mailto:info@nammu.ch">info@nammu.ch</a>
					</motion.p>
				</address>
			</div>

			<div className="address-icon">
				<div className="icon" onClick={() => setContactForm(!contactForm)}>
					<motion.div
						initial="visible"
						animate={contactForm ? 'hidden' : 'visible'}
						variants={iconVariants}
					>
						<MailTwoTone twoToneColor="#90a8b0" />
					</motion.div>
					<motion.div
						initial="hidden"
						animate={contactForm ? 'visible' : 'hidden'}
						variants={iconVariants}
					>
						<EnvironmentTwoTone twoToneColor="#90a8b0" />
					</motion.div>
				</div>
			</div>
		</section>
	);
};

export default connect()(Contact);
